<template>
  <div class="radio-group" :class="isText ? 'radio-group-text' : 'radio-group-icon'">
    <label class="toolbar-title text-primary" v-if="label && !hiddenLable">{{ label }}</label>
    <div class="radio-container" v-if="options.length" ref="radioContainer">
      <template v-for="(option, index) in options">
        <label v-if="option.value" @click.stop="handleClick(option)" :for="`input-${labelSlug}-${index}`" :key="`label${index}`" :class="checked == option.value ? 'active' : ''" :title="$t(option.icon ? option.text || option.value : '' || option.tooltip)">
          <component :is="option.icon" :style="{ color: getColor(option.value), width: '16px', height: '16px' }" v-if="option.icon" />
          <span :style="{ color: getColor(option.value) }" v-else>{{ option.text }}</span>
        </label>
        <input :id="`input-${labelSlug}-${index}`" type="radio" :key="`input-${labelSlug}-${index}`" :value="option.value" v-model="checked" />
      </template>
      <div class="indicator" v-show="checked !== null" :style="{ left: left + 'px', width: width + 'px' }"></div>
    </div>
  </div>
</template>

<script>
import i18n from '@/libs/i18n';
export default {
  props: ['name', 'label', 'options', 'value', 'toggle', 'hiddenLable', 'moveCount'],
  mounted() {
    if ((this.options || []).length) {
      this.isText = (this.options[0] || {}).text;
    }
    this.moveIndicator();
  },
  data() {
    return {
      checked: this.value,
      isText: false,
      left: 0,
      width: 0,
    };
  },
  computed: {
    labelSlug() {
      if (!this.name) return 'default';
      return this.name.split(' ').join('-').toLowerCase();
    },
    locale() {
      return i18n.locale;
    },
  },
  watch: {
    moveCount() {
      setTimeout(() => {
        this.moveIndicator();
      }, 0);
    },
    value(val) {
      if (val) {
        this.checked = val;
        this.moveIndicator();
      } else this.width = 0;
    },
    checked(checked) {
      this.$emit('input', checked);
      this.moveIndicator();
    },
    locale() {
      setTimeout(() => {
        this.moveIndicator();
      }, 0);
    },
  },
  methods: {
    moveIndicator() {
      try {
        if (!this.checked) return;
        let index = this.options.findIndex((option) => option.value === this.checked);
        let radioRect = this.$refs.radioContainer.getBoundingClientRect();
        let domlabel = document.querySelector(`[for="input-${this.labelSlug}-${index}"]`);
        if (domlabel) {
          let labelRect = domlabel.getBoundingClientRect();
          this.left = labelRect.left - (radioRect.left + 1); // 1 for border
          this.width = labelRect.width;
        }
      } catch (error) {
        this.width = 0;
      }
    },
    getColor(value) {
      if (this.toggle && this.value === null) return '#808080';
      if (this.checked === value) return 'inherit';
      return '#808080';
    },
    handleClick(option) {
      if (this.toggle && option) {
        if (this.checked === option.value) {
          if (this.value === null) {
            this.$emit('input', this.checked);
            this.moveIndicator();
          } else {
            this.$emit('input', null);
            this.width = 0;
          }
        }
      }
      this.$emit('clickOption', option);
    },
  },
};
</script>

<style scoped>
.radio-group {
  width: fit-content;
}

.radio-container {
  border: 1px solid rgba(115, 103, 240, 0.24);
  border-radius: 0.357rem;
  padding: 4px;
  display: flex;
  position: relative;
  user-select: none;
}

.radio-container input {
  display: none;
}

.radio-container label {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 40px; */
  height: 30px;
  margin: 0;
  padding: 0 11px;
  cursor: pointer;
  border-radius: 0.357rem;
  margin-right: 4px;
  white-space: nowrap;
  width: fit-content;
}

.radio-container label:last-of-type {
  margin-right: 0;
}

.radio-container .indicator {
  position: absolute;
  height: 32px;
  background-color: rgba(57, 123, 255, 0.12);
  border-radius: 0.357rem;
  top: 4px;
  pointer-events: none;
  transition: left 0.25s, width 0.25s;
}

.radio-container svg,
.radio-container span {
  transition: color 0.25s;
}

/* .radio-container label:hover svg {
  color: #ffffff !important;
} */
/* .radio-group-text .radio-container label {
  width: 100%;
  padding: 10px;
  background-color: rgba(57, 123, 255, 0.12);
  border-radius: 0.357rem;
} */
/* .radio-group-text .radio-container label.active {
  background-color: rgba(57, 123, 255, 0.12);
} */
/* .radio-group-text .radio-container label span {
  white-space: nowrap;
} */
/* .radio-group-text .radio-container .indicator {
  display: none;
} */
</style>
